.Footer {
	margin-top: 80px;
	margin-bottom: 80px;

	&::after { width: 5%; }

	a {
		color: $color-text;
		display: block;
		text-decoration: none;
		transition: all $hover-transition-duration ease-in-out;

		&:hover { transform: translateY(-4px); }
	}
}

.Footer__wrap {
	@media (min-width: 650px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.Footer__icon-list {
	align-self: flex-end;

	li {
		margin-right: 16px;
	}
}

.Footer__icon {
	display: block;
	width: 18px;
	color: $color-text;
	opacity: .4;
	transition: all $hover-transition-duration ease-in-out;

	&:hover {
		opacity: 1;
		transform: scale(1.06) translateY(-4px);
	}
}

$CaseStudy-transition-duration: $hover-transition-duration;

.CaseStudy {
	color: $color-text;
	display: block;
	text-decoration: none;
}

.CaseStudy__title { font-weight: bold; }

.CaseStudy__company {
	display: block;
	margin-top: 8px;
}

.CaseStudy__image-wrapper {
	background: #F6F6F6;
	margin-bottom: 16px;
	padding-top: 75%;
	position: relative;
	transition: all $CaseStudy-transition-duration ease-in-out;

	.CaseStudy:hover & {
		background: $color-accent;
	}
}

.CaseStudy__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: all $CaseStudy-transition-duration ease-in-out;

	.CaseStudy:hover & {
		transform: scale(1.06);
		box-shadow: 0 20px 50px rgba(0,0,0,.2);
	}
}

@import './variables';
@import './components/index';
@import './modules/index';

body {
	background: $color-page;
	font-size: 4vw;
	font-family: $font-family;
	line-height: 1.4;
	margin: 0;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media (max-width: 400px) {
		font-size: 16px;
	}

	@media (min-width: 800px) {
		font-size: 32px;
	}
}

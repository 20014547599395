.List {
	list-style: none;
	&, li { box-sizing: border-box; }
}

.List--dashed {
	list-style: none;
	padding-left: 1.75em;

	li { margin-top: 1em; }
	li:first-child { margin-top: 0; }

	li:before {
		float: left;
		font-size: 1em;
		margin-left: -1.5em;
		width: 1.5em;
		content: '\2013';
	}
}

.List--reset,
.List--inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.List--inline {
	display: flex;
	flex-direction: row;
}

.List--6 li { margin-bottom: 48px; }
.List--5 li { margin-bottom: 40px; }
.List--4 li { margin-bottom: 32px; }
.List--3 li { margin-bottom: 24px; }
.List--2 li { margin-bottom: 16px; }
.List--1 li { margin-bottom: 8px; }

.List li:last-child { margin-bottom: 0; }

/* Columns */
.List--columns-2,
.List--columns-3,
.List--columns-4 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 0 -8px;

	li {
		flex: 0 0 auto;
		width: 100%;
		padding: 0 8px;
	}
}

.List--columns-2 {
	@media (min-width: 1450px) {
		flex-direction: row;

		li { width: 50%; }
	}
}

.List--columns-3 {
	@media (min-width: 700px) {
		flex-direction: row;

		li { width: 50%; }
	}

	@media (min-width: 1450px) {
		li { width: 33%; }
	}
}

.List--columns-4 {
	@media (min-width: 700px) {
		flex-direction: row;

		li { width: 50%; }
	}

	@media (min-width: 1450px) {
		li { width: 33%; }
	}

	@media (min-width: 2000px) {
		li { width: 25%; }
	}
}

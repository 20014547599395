.Asset--image img {
	border-style: none;
	display: block;
	height: auto;
	max-width: 100%;
	width: 100%;
}

.Asset--hoverable .Asset__link {
	display: block;
	transition: all $hover-transition-duration ease-in-out;

	&:hover {
		transform: scale(1.06);
		box-shadow: 0 20px 50px rgba(0,0,0,.2);
	}
}

.Asset__caption {
	display: block;
	margin-top: 1em;
}

.WorkHeader__meta {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15vw;
	margin-bottom: 2em;
	width: 94vw;

	& > * {
		box-sizing: border-box;
		padding-right: 4em;
	}

	a {
		color: $color-text;
		display: block;
		text-decoration: none;
		transition: all $hover-transition-duration ease-in-out;

		&:hover { transform: translateY(-4px); }
	}
}

.WorkHeader--quad .WorkHeader__meta > * { width: 50%; }

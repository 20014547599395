.MetaLink {
	color: $color-text;
	display: block;
	text-decoration: none;
	transition: all $hover-transition-duration ease-in-out;

	&:hover { transform: translateY(-4px); }

	.Link { position: static; }
}

.MetaLink__meta {
	display: block;
	margin-top: 8px;
}

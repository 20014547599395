.Section {
	margin-left: 8vw;
	margin-right: 18vw;

	& + & { margin-top: 160px; }
}

/* Padding */
.Section--padding-small {
	margin-left: 3vw;
	margin-right: 3vw;

	.Section__title {
		margin-left: 5vw;
		margin-right: 15vw;
	}
}

/* Content Types */
.Section--text .Section__content,
.Section--two-col-list .Section__content { max-width: 28em; }

@media (min-width: 1450px) {
	.Section--two-col-list {
		.Section__content { max-width: none; }
	}
}
